import React, { useEffect, useState } from 'react';

import { addChangeView,addChangeLlogin } from '../../../store/pathActions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from "react-router-dom";
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';

import RouteLink from '../../../components/Routing/RouteLink';
import AuthContainer from '../../../components/site/Auth/AuthContainer/AuthContainer';
import AuthTextBox from '../../../components/site/Auth/AuthTextBox/AuthTextBox';
import AuthAlert from '../../../components/site/Auth/AuthAlert/AuthAlert';

import API from "../../../modules/api";

import Utils from "../../../modules/utils";
import './Login.css'


const Login = (props) => {
  const utl = new Utils();
  const { id } = useParams();
  

  const lang = useSelector((state) => state.lang)
  const tranlates = useSelector((state) => state.tranlates);

  const navigate = useNavigate();

  const dispatch = useDispatch()
  const onRegisterclicked = (e) => {
    e.preventDefault();
    dispatch(addChangeView("/register"))
    navigate("/register")
  }



  const [itemUseState, setItemUseState] = useState({
    username: "",
    password: "",
  })

  const [myState, setMyState] = useState({
    email_label: "ایمیل",
    password_label: "کلمه عبور",
    forget_password_label: "کلمه عبور را فراموش کرده ام",
    btn_sign_in_title: "ورود",
    btn_sign_in_google_title: "ورود با گوگل",
    or_label: "یا",
    or_option_label: "با ایمیل کاری خود وارد شوید",
    dont_have_account_label: "حساب کاربری ندارید",
    register_label: "ثبت نام کنید",
    is_error:false,
    err_message:"",
    site_login:""

  })

  useEffect(() => {
   
    loadTransaltes();


  }, [])
  useEffect(() => {
    loadTransaltes();


  }, [tranlates])


  
  const handleForgetPasswordClick = async(event) => {
		event.preventDefault();
    const tr = await tranlates;
		if(itemUseState !=null && itemUseState.username!=null && itemUseState.username.trim().length==0){
			new Utils().showAlert(utl.translateWord(tr,"اخطار"),utl.translateWord(tr, "نام کاربری مشخص نشده است"),"error")
		}

		new API().sendPostWithoutToken('/api/login/forget',{username:itemUseState.username},(result)=>{
			console.log('result', result)
			new Utils().showAlert(utl.translateWord(tr,"توجه"),utl.translateWord(tr,"کلمه عبور جدید برای شما پیامک گردید"),"success")
		},(result)=>{
			console.log('result', result)
			console.log('response', result.response)
			if(result.response.status==501){
				new Utils().showAlert(utl.translateWord(tr,"اخطار"),utl.translateWord(tr,result.response.data.message),"error")
			}else{
				new Utils().showAlert(utl.translateWord(tr,"اخطار"),utl.translateWord(tr,"درخواست شما معتبر نمیباشد"),"error")
			}
		})
	}

  const google_login = useGoogleLogin({
    onSuccess: tokenResponse => handleSuccess(tokenResponse),
    onError:error => handleError(error)
  });

  const loadTransaltes = () => {
    try {
      tranlates.then(result => {

        const obj = {
          email_label: utl.translateWord(result, myState.email_label),
          password_label: utl.translateWord(result, myState.password_label),
          forget_password_label: utl.translateWord(result, myState.forget_password_label),
          btn_sign_in_title: utl.translateWord(result, myState.btn_sign_in_title),
          btn_sign_in_google_title: utl.translateWord(result, myState.btn_sign_in_google_title),
          or_label: utl.translateWord(result, myState.or_label),
          or_option_label: utl.translateWord(result, myState.or_option_label),
          dont_have_account_label: utl.translateWord(result, myState.dont_have_account_label),
          register_label: utl.translateWord(result, myState.register_label),
        }

        setMyState({ ...obj })


      })
    } catch {

    }
  }



  
  const handleChange = (e) => {
    const { name, value } = e.target;



    setItemUseState({
      ...itemUseState,
      [name]: value,
    });

  };



  const handleSuccess = (response) => {
    console.log("AAA",response);
    console.log("BBBB",response.access_token);
    (new API()).sendPostWithoutToken('/api/login/user/google',{token:response.access_token},function (result) {
      localStorage.setItem("token", result.token)
      dispatch(addChangeLlogin(true))

      if(id!=null){
      
        dispatch(addChangeView(`/${id}`))
        navigate(`/${id}`)
      }else{
      window.location.href=`https://roles.tradinglocally.com/?t=${result.token}`;
        // dispatch(addChangeView("/"))
        // navigate("/")
      }
     
      console.log(result);
    },function (err) {
      try {
        tranlates.then(result => {
         

          setMyState({ ...myState, is_error: true, err_message :utl.translateWord(result, "ورود موفقیت آمیز نبود") ,lst_err:[] });
        })
      } catch {
        setMyState({ ...myState, is_error: true,  err_message : "ورود موفقیت آمیز نبود" ,lst_err:[]});
      }
    });
    // alert('')
    
    console.log('Login Success:', response);
    console.log('Login SuccessB:', response.access_token);

  };

  const handleError = (error) => {
    console.log('Login Failed:', error);
    try {
      tranlates.then(result => {
       

        setMyState({ ...myState, is_error: true, err_message :utl.translateWord(result, "ورود موفقیت آمیز نبود") ,lst_err:[] });
      })
    } catch {
      setMyState({ ...myState, is_error: true,  err_message : "ورود موفقیت آمیز نبود" ,lst_err:[]});
    }
  };



  const checkValidation = () => {
    setMyState({ ...myState, is_error: false });

    let is_error = false;
    let lst_err = [];



    if (itemUseState.username.trim().length == 0) { lst_err = [...lst_err, "نام کاربری"]; is_error = true; }



    if (itemUseState.password.trim().length == 0) { lst_err = [...lst_err, "کلمه عبور"]; is_error = true; }


    if (is_error) {
      try {
        tranlates.then(result => {
          for (let index = 0; index < lst_err.length; index++) {
            const element = lst_err[index];
            lst_err[index]=utl.translateWord(result,element)
            
          }

          setMyState({ ...myState, is_error: true, err_message :utl.translateWord(result, "اطلاعات ناقص است") ,lst_err:lst_err });
        })
      } catch {
        setMyState({ ...myState, is_error: true,  err_message : "اطلاعات ناقص است" ,lst_err:lst_err});
      }
    }



  }

  const onLoginUser = (event) => {
    event.preventDefault();

    checkValidation();

    if (myState.is_error)
      return;


    (new API()).sendPostWithoutToken('/api/login/user',itemUseState,function (result) {
      localStorage.setItem("token", result.token)
      dispatch(addChangeLlogin(true))
      if(id!=null){
      
        dispatch(addChangeView(`/${id}`))
        navigate(`/${id}`)
      }else{
        window.location.href=`https://roles.tradinglocally.com/?t=${result.token}`;
        // dispatch(addChangeView("/"))
        // navigate("/")
      }
      // dispatch(addChangeView("/"))
      // navigate("/")
      console.log(result);
    },function (err) {
      try {
        tranlates.then(result => {
         

          setMyState({ ...myState, is_error: true, err_message :utl.translateWord(result, "نام کاربری یا کلمه عبور اشتباه است") ,lst_err:[] });
        })
      } catch {
        setMyState({ ...myState, is_error: true,  err_message : "نام کاربری یا کلمه عبور اشتباه است" ,lst_err:[]});
      }
      console.log(err)
      
    })

  }
  return (
    <AuthContainer title="خوش آمدید" dsc="نام کاربری و کلمه عبور خود را وارد کنید">
 {myState.is_error ? <AuthAlert message={myState.err_message} errors={myState.lst_err} /> : null}
      <form action="submit">
        <AuthTextBox type='email' name="username" changed={handleChange} placeholder={myState.email_label} />
        <AuthTextBox type='password' name="password"  changed={handleChange} placeholder={myState.password_label}>
          <small><a onClick={handleForgetPasswordClick} href="#">{myState.forget_password_label}</a></small>
        </AuthTextBox>

        <div className="form-group">
          <button className="btn btn_main" type="submit" onClick={onLoginUser}>{myState.btn_sign_in_title} <i className="icofont-arrow-right"></i></button>
        </div>
      </form>
      <p className="or_block">
        <span>{myState.or_label}</span>
      </p>
      <div className="or_option">
        <p>{myState.or_option_label}</p>

        <a href="#" className="btn google_btn" onClick={google_login}><img src="/assets/images/google.png" alt="image" /> <span>{myState.btn_sign_in_google_title}</span> </a>
      
        <p>{myState.dont_have_account_label}  <RouteLink href={(id!=null?`/register/${id}`:'/register')}>{myState.register_label}</RouteLink></p>
      </div>
    </AuthContainer>
  );
};

export default Login;