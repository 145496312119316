import React from 'react'

function AcceptModal({changed}) {
    return (
        <>

            <div className='modal-backdrop' style={{ opacity: "0.8" }}></div>
            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" style={{ display: "block",height:"90vh",paddingTop:"10%", opacity: "1.0", overflowX: "hidden", overflowY: "auto" }} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">توافق‌نامه استفاده از پلتفرم Trading Locally          </h5>
                            <button type="button" onClick={()=>changed(false)} className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                        </div>

                        <div className="modal-body">
                            <h1 className='text-center'>به نام خدا</h1>
                            <h5 className='text-right'>کاربر گرامی</h5>
                            <p className='text-right'>



                                با ورود به پلتفرم Trading Locally، شما به یک پلتفرم معاملاتی پیشرفته دسترسی پیدا می‌کنید که با ابزارهای حرفه‌ای، آموزش‌های کاربردی و پشتیبانی مستمر، شما را در مسیر موفقیت مالی همراهی می‌کند. این توافق‌نامه جهت شفاف‌سازی رابطه حقوقی بین شما (کاربر) و شرکت Trading Locally (شرکت) تنظیم شده است. لطفاً پیش از انتخاب اشتراک، مفاد آن را به دقت مطالعه فرمایید.

                            </p>


                            <h5 className='text-right'>۱. تعریف خدمات</h5>

                            <p className='text-right'>

                                شرکت Trading Locally یک پلتفرم آنلاین است که خدمات زیر را به کاربران ارائه می‌دهد:

                                ابزارهای معاملاتی پیشرفته جهت تحلیل و انجام معاملات در بازارهای مالی.

                                آموزش‌های متنوع در سطوح مبتدی تا پیشرفته.

                                پشتیبانی فنی و آموزشی ۲۴ ساعته در ۷ روز هفته.

                                بسته‌های فروشی ویژه با امتیازات خاص که در صفحه هر اشتراک به کاربر اعلام می‌شود.


                                اشتراک‌ها: کاربر می‌تواند اشتراک ماهانه یا سالانه را انتخاب نماید. هزینه‌ها و شرایط پرداخت در زمان خرید به صورت شفاف اعلام می‌شود.


                                ---
                            </p>


                            <h5 className='text-right'> ۲. حریم خصوصی
                            </h5>

                            <p className='text-right'>


                                شرکت تمام تلاش خود را به کار می‌گیرد تا حریم خصوصی کاربران را حفظ نماید. کلیه اطلاعات شخصی کاربران مطابق با سیاست حفظ حریم خصوصی شرکت محافظت می‌شود. اطلاعات کاربران بدون رضایت ایشان، مگر در مواردی که قانوناً ملزم به افشا باشد، با اشخاص ثالث به اشتراک گذاشته نمی‌شود.


                            </p>


                            <h5 className='text-right'> ۳. تعلیق و تمدید خدمات
                            </h5>

                            <p className='text-right'>


                                شرکت تمام تلاش خود را به کار می‌گیرد تا پلتفرم به صورت مداوم در دسترس باشد؛ اما در شرایط زیر امکان قطع موقت خدمات وجود دارد:

                                نگهداری برنامه‌ریزی‌شده یا به‌روزرسانی سیستم‌ها: شرکت حداقل ۲۴ ساعت قبل از طریق ایمیل یا اعلان در پلتفرم به کاربران اطلاع‌رسانی می‌کند.

                                حوادث غیرمترقبه: مانند قطعی سرور یا مشکلات فنی خارج از کنترل.


                                جبران خسارت: در صورت قطعی سرویس بیش از ۲۴ ساعت متوالی، مدت زمان اشتراک کاربر به میزان زمان قطعی تمدید خواهد شد.


                            </p>


                            <h5 className='text-right'> ۴. سلب مسئولیت
                            </h5>

                            <p className='text-right'>



                                1. شرکت Trading Locally هیچ‌گونه تضمینی در خصوص سودآوری یا موفقیت مالی کاربران ارائه نمی‌دهد. معاملات دارای ریسک بوده و کاربران مسئول تصمیمات معاملاتی خود هستند.


                                2. شرکت مسئولیتی در قبال خسارات ناشی از استفاده نادرست از پلتفرم، اشتباهات کاربری یا تصمیمات معاملاتی ندارد.


                                3. شرکت مسئولیتی در قبال قطعی‌های ناشی از حوادث غیرمترقبه، حملات سایبری یا مشکلات فنی خارج از کنترل خود ندارد؛ اما متعهد به رفع سریع مشکلات و اطلاع‌رسانی به کاربران است.


                                4. عدم مسئولیت در قبال کلاهبردارانی که به ناحق از نام شرکت استفاده می‌کنند:

                                هرگونه سوءاستفاده از عنوان و نماد شرکت Trading Locally، شامل استفاده نادرست یا نمایندگی غیرمجاز، تحت پیگرد قانونی قرار می‌گیرد.

                                کاربران موظفند تنها از کانال‌های رسمی شرکت استفاده نمایند:

                                اینستاگرام:
                                Tradinglocally
                                وبسایت رسمی:
                                Tradinglocally.com

                                تلگرام:
                                @tradinglocally


                                5. در صورت بروز هرگونه اختلاف ناشی از سوءاستفاده از نام شرکت یا سایر دعاوی، کاربران موظفند ابتدا موضوع را از طریق کانال‌های رسمی شرکت مطرح نموده و برای حل‌وفصل آن از طریق مذاکره تلاش کنند.

                                چنانچه مذاکره در مدت ۳۰ روز کاری به نتیجه نرسد، طرفین توافق می‌کنند که موضوع را از طریق مراجع قانونی، از جمله سامانه شورای حل اختلاف یا درگاه ملی قوه قضاییه، به صورت رسمی پیگیری نمایند.





                            </p>


                            <h5 className='text-right'>   ۵. امضای الکترونیکی
                            </h5>

                            <p className='text-right'>




                                کاربر با انتخاب گزینه «موافقت با شرایط و ضوابط» و تکمیل فرآیند خرید، این توافق‌نامه را به صورت الکترونیکی امضا می‌کند. این امضا مطابق با قوانین تجارت الکترونیک جمهوری اسلامی ایران معتبر و الزام‌آور است.



                            </p>


                            <h5 className='text-right'>    ۶. قوانین حاکم و حل اختلاف
                            </h5>

                            <p className='text-right'>





                                این توافق‌نامه تابع قوانین جمهوری اسلامی ایران است. در صورت بروز اختلاف:

                                طرفین ابتدا از طریق مذاکره و توافق دوستانه تلاش می‌کنند مشکل را حل کنند.

                                در صورت عدم توافق، اختلافات از طریق مراجع آنلاین حل اختلاف مانند سامانه شورای حل اختلاف و درگاه ملی قوه قضاییه مورد بررسی قرار گرفته و رأی نهایی به عنوان مرجع حل اختلاف تلقی می‌شود.

                                هرگونه دعوی ناشی از سوءاستفاده از نام شرکت از طریق مراجع قانونی و قضایی پیگیری خواهد شد.



                            </p>


                            <h5 className='text-right'>     ۷. تماس با پشتیبانی
                            </h5>

                            <p className='text-right'>




                                در صورت داشتن هرگونه سوال یا نیاز به راهنمایی، تیم پشتیبانی شرکت از طریق روش‌های رسمی زیر آماده پاسخگویی است:

                                تیکت وب‌سایت
                                Tradinglocally.com
                                شماره تلفن ارائه‌شده
                                02128423079
                                آیدی تلگرام
                                tradinglocally_support

                            </p>


                            <h5 className='text-right'>    حرف آخر
                            </h5>

                            <p className='text-right'>



                                با امضای این توافق‌نامه، کاربر به عضویت در پلتفرم Trading Locally درمی‌آید و شرکت متعهد به ارائه خدمات با کیفیت و پشتیبانی مستمر است. تمامی تلاش‌های ما در جهت حفظ حقوق کاربران و جلوگیری از سوءاستفاده از نام و برند شرکت به کار گرفته شده است.</p>
                        </div>

                        <div className="modal-footer">
                            <button type="button" onClick={()=>changed(false)} className="btn btn-secondary" data-bs-dismiss="modal">عدم تائید و بستن</button>
                            <button type="button" onClick={()=>changed(true)} className="btn btn-primary">تائید و ادامه</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AcceptModal