import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Utils from "../../../modules/utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faLongArrowAltLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { addChangeView, addChangePageTitle, addChangeBasket, addChangeLlogin } from '../../../store/pathActions'
import { useNavigate } from "react-router-dom";

import API from "../../../modules/api";

import './ShoppingCard.css'
import AcceptModal from './AcceptModal';
function ShoppingCard() {
  const utl = new Utils();
  const txtCopont = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.lang)
  const tranlates = useSelector((state) => state.tranlates);
  const [modalState, setModalState] = useState(false);
  const [basketState, setBasketState] = useState([]);
  const [myState, setMyState] = useState({
    
    page_title: "سبد خرید",
    master_title: "سفارش شما",
    master_sub_title: "سبد خرید",
    item_count_title: "شما تعداد",
    item_count_postfix: "قلم در سبد خرید دارید",
    count_title: "تعداد",
    count_postfix: "روز",
    price_card_title: "جزئیات مالی",
    copon_title: "کد تخفیف",
    copon_execute_title: "اعمال تخفیف",
    go_to_basket_title: "مشاهده سبد خرید",
    basket_success_message: 'به سبد خرید اضافه شد',
    jam_col_dollar_title: 'جمع کل دلار',
    jam_col_rial_title: 'جمع کل ریال',
    copont_price_title: 'تخفیف',
    btn_dollar_pardakht_title: 'پرداخت دلاری',
    btn_rial_pardakht_title: 'پرداخت ریالی',
    question_delete_title: 'توجه',
    question_delete_message: 'ایا اطلاعات حذف شود؟',
    question_delete_confirm: 'بله حذف کن',
    question_delete_cancle: 'انصراف',
    question_ok_title: 'تایید',
    question_ok_message: 'اطلاعات تغییر یافت',
    jam_col_dollar: 0,
    jam_col_dollar: 0,
    jam_col_rial: 0,
    copont_price: 0,
    jam_pardakht_dollar: 0,
    jam_pardakht_rial: 0,
    jam_takhfif: 0,
    percent_takhfif: 0,
    execute_takhfif:false,
    copon_id:null
  });



  useEffect(() => {
    try {
      tranlates.then(result => {
        const obj = {
          page_title: utl.translateWord(result, "سبد خرید"),
          master_title: utl.translateWord(result, "سفارش شما"),
          master_sub_title: utl.translateWord(result, "سبد خرید"),
          item_count_title: utl.translateWord(result, "شما تعداد"),
          item_count_postfix: utl.translateWord(result, "قلم در سبد خرید دارید"),
          count_title: utl.translateWord(result, "تعداد"),
          count_postfix: utl.translateWord(result, "روز"),
          price_card_title: utl.translateWord(result, "جزئیات مالی"),
          copon_title: utl.translateWord(result, "کد تخفیف"),
          copon_execute_title: utl.translateWord(result, "اعمال تخفیف"),
          jam_col_dollar_title: utl.translateWord(result, 'جمع کل دلار'),
          jam_col_rial_title: utl.translateWord(result, 'جمع کل ریال'),
          copont_price_title: utl.translateWord(result, 'تخفیف'),
          btn_dollar_pardakht_title: utl.translateWord(result, 'پرداخت دلاری'),
          btn_rial_pardakht_title: utl.translateWord(result, 'پرداخت ریالی'),
          question_delete_title: utl.translateWord(result, 'توجه'),
          question_delete_message: utl.translateWord(result, 'ایا اطلاعات حذف شود؟'),
          question_delete_cancle: utl.translateWord(result, 'انصراف'),
          question_delete_confirm: utl.translateWord(result, 'بله حذف کن'),
          question_ok_title: utl.translateWord(result, 'تایید'),
          question_ok_message: utl.translateWord(result, 'اطلاعات تغییر یافت'),

        };

        setMyState({ ...myState, ...obj })
      })
    } catch {

    }


  }, [lang])

  const loadTitle = () => {




    dispatch(addChangePageTitle(myState.page_title))
  }
  useEffect(() => {
    loadCard();
    loadTitle();
  }, [])
  const loadCard = () => {
    let basket = localStorage.getItem("basket");
    if (!basket)
      basket = "[]";
    try {
      const arr_basket = JSON.parse(basket);
      let jam_dollar = 0;
      let jam_rial = 0;
      for (let index = 0; index < arr_basket.length; index++) {
        const element = arr_basket[index];
        jam_dollar += parseFloat(element.dollar_price);
        jam_rial += parseInt(element.rial_price);
      }
      const tkh = (myState.percent_takhfif?myState.percent_takhfif:0);
      const thk_d =( jam_dollar*tkh)/100;
      console.log('thk_d', thk_d);
      console.log('thk_d',  (jam_dollar- thk_d));
      const thk_r =( jam_rial*tkh)/100;
      const obj = {
        jam_col_dollar: jam_dollar, jam_col_rial: jam_rial
        , jam_pardakht_dollar: (jam_dollar- thk_d), jam_pardakht_rial: (jam_rial-thk_r),jam_takhfif:thk_d,execute_takhfif:false
      }

      console.log('first', obj)
      setMyState({
        ...myState, ...obj
      })
      dispatch(addChangeBasket(arr_basket.length))
      setBasketState(arr_basket);
    } catch (error) {
      setBasketState([]);
      dispatch(addChangeBasket(0))
    }


  }
  useEffect(() => {
    console.log('zzz', myState)
if(myState.execute_takhfif==true){
  loadCard();
}
  }, [myState])
  useEffect(() => {
    let jam_dollar = 0;
    let jam_rial = 0;
    for (let index = 0; index < basketState.length; index++) {
      const element = basketState[index];
      jam_dollar += parseFloat(element.dollar_price);
      jam_rial += parseInt(element.rial_price);
    }
    
    const tkh = (myState.percent_takhfif?myState.percent_takhfif:0);
      const thk_d =( jam_dollar*tkh)/100;
      console.log('thk_d', thk_d);
      console.log('thk_d',  (jam_dollar- thk_d));
      const thk_r =( jam_rial*tkh)/100;
      const obj = {
        jam_col_dollar: jam_dollar, jam_col_rial: jam_rial
        , jam_pardakht_dollar: (jam_dollar- thk_d), jam_pardakht_rial: (jam_rial-thk_r),jam_takhfif:thk_d,execute_takhfif:false
      }

    console.log('first', obj)
    setMyState({
      ...myState, ...obj
    })

  }, [basketState])
  const onDeleteItem = (event, item) => {
    event.preventDefault();

    Swal.fire({
      title: myState.question_delete_title,
      text: myState.question_delete_message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: myState.question_delete_confirm,
      cancelButtonText: myState.question_delete_cancle,
    }).then((result) => {
      if (result.isConfirmed) {

        let mydata = basketState;

        const index = mydata.map(function (e) { return e.id; }).indexOf(item.id);
        if (index > -1) { // only splice array when item is found
          mydata.splice(index, 1); // 2nd parameter means remove one item only
          localStorage.setItem("basket", JSON.stringify(mydata));
          loadCard();
          //setBasketState([mydata])
        }
        Swal.fire({
          title: myState.question_ok_title,
          text: myState.question_ok_message,
          icon: "success"
        });
      }
    });


  }

  const onRialGatewayClicked = async (event) => {
    event.preventDefault();

    setModalState(true);

    return;
  
  }

  const onRialGatewayAcceptClicked = async (status) => {
    setModalState(false);
    if(status==false){
      return;
   
    }

    (new API()).sendGETWithToken("/", () => {
      (new API()).sendPostWithToken("/api/orders", { items: basketState,copon_id:myState.copon_id }, function (result) {
        console.log('result', result)
        console.log('result', utl.isValidHttpUrl(result.url))
        if (utl.isValidHttpUrl(result.url)) {
          window.location.href = result.url;
        }
        // localStorage.removeItem("basket");
        //  dispatch(addChangeBasket(0))
        //  setBasketState([]);
      })
      dispatch(addChangeLlogin(true))
    }, (err) => {

      dispatch(addChangeLlogin(false))
      dispatch(addChangeView("/login/shopping-card"))
      navigate("/login/shopping-card")


    })
  }

  const style1 = {
    backgroundColor: "#eee"
  }
  const handleCoponClick =async (event) => {
    event.preventDefault();
    const tr= await tranlates;
    if(myState.execute_takhfif){
      utl.showAlert("!",utl.translateWord(tr,"تخفیف قبلا اعمال شده است"),"error");
      return;
    }
   
    if(txtCopont.current.value.trim().length==0){

      utl.showAlert("!",utl.translateWord(tr,"اطلاعاتی وارد نشده است"),"error");
    }else{
      (new API()).sendGETWithToken("/", () => {
        (new API()).sendGETWithToken(`/api/copons/text/${txtCopont.current.value.trim()}`, function (result) {
          console.log('result', result);
          
          if(result==null){
            utl.showAlert("!",utl.translateWord(tr,"تخفیفی با این مشخصات وجود ندارد"),"warning");
          }else{
            if(!result.is_public || !result.is_percent){
              utl.showAlert("!",utl.translateWord(tr,"تخفیفی با این مشخصات وجود ندارد"),"warning");
            }else{
              if(!result.is_active){
                utl.showAlert("!",utl.translateWord(tr,"تخفیفی با این  مشخصات وجود ندارد"),"warning");
                
              }else{
                setMyState({...myState,percent_takhfif:result.price,execute_takhfif:true,copon_id:result.id})
                utl.showAlert("!",utl.translateWord(tr,"تخفیف اعمال شد"),"warning");
              }
                
            }
           
          }
          // localStorage.removeItem("basket");
          //  dispatch(addChangeBasket(0))
          //  setBasketState([]);
        })
        
        dispatch(addChangeLlogin(true))
      }, (err) => {
  
        dispatch(addChangeLlogin(false))
        dispatch(addChangeView("/login/shopping-card"))
        navigate("/login/shopping-card")
  
  
      })
      
    }
  }
  return (
  <>
    <section className="h-100 h-custom basket-setion" style={style1}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card">
              <div className="card-body p-4">

                <div className="row">

                  <div className="col-lg-7">
                    <h5 className="mb-3"><a href="#!" className="text-body">{myState.master_title} <FontAwesomeIcon icon={faLongArrowAltLeft} className='me-2' /></a></h5>
                    <hr />

                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div>
                        <p className="mb-1">{myState.master_sub_title}</p>
                        <p className="mb-0">{myState.item_count_title} {basketState.length} {myState.item_count_postfix}</p>
                      </div>
                      <div>
                        {/* <p className="mb-0"><span className="text-muted">Sort by:</span> <a href="#!"
                        className="text-body">price <i className="fas fa-angle-down mt-1"></i></a></p> */}
                      </div>
                    </div>
                    {
                      basketState.map((item) => {
                        return <div className="card mb-3" key={item.id}>
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex flex-row align-items-center">

                                <div className="ms-3">
                                  <h5>{item.product.name}-{item.name}</h5>
                                  <p className="small mb-0">{myState.count_title} {item.day_count} {myState.count_postfix}</p>
                                </div>
                              </div>
                              <div className="d-flex flex-row align-items-center">
                                <div style={{ width: "100px" }}>
                                  <h5 className="fw-normal mb-0">{item.rial_price.toLocaleString('en-US', { minimumFractionDigits: 0 })} ريال</h5>
                                </div>
                                <div style={{ width: "100px" }}>
                                  <h5 className="mb-0">{item.dollar_price.toLocaleString('en-US', { minimumFractionDigits: 2 })} $</h5>
                                </div>
                                <a href="#!" onClick={(event) => onDeleteItem(event, item)} style={{ color: "#cecece" }}><FontAwesomeIcon icon={faTrashAlt} /> </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      })

                    }




                  </div>
                  <div className="col-lg-5">

                    <div className="card bg-primary text-white rounded-3">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <h5 className="mb-0">{myState.price_card_title}</h5>

                        </div>



                        <div className="mt-4">
                          <div data-mdb-input-init className="form-outline form-white mb-4">
                            <input ref={txtCopont} type="text" className="form-control form-control-lg" siez="17"
                              placeholder={myState.copon_title} />
                            <label className="form-label">{myState.copon_title}</label>
                          </div>
                          <button type="button" onClick={handleCoponClick} className="btn btn-info btn-block btn-lg">
                            <span>{myState.copon_execute_title} <FontAwesomeIcon icon={faLongArrowAltLeft} /></span>
                            <div className="d-flex justify-content-between">
                            </div>
                          </button>

                        </div>

                        <hr className="my-4" />

                        <div className="d-flex justify-content-between">
                          <p className="mb-2">{myState.jam_col_dollar_title}</p>
                          <p className="mb-2">${myState.jam_col_dollar.toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-2">{myState.jam_col_rial_title}</p>
                          <p className="mb-2">{myState.jam_col_rial.toLocaleString('en-US', { minimumFractionDigits: 0 })} ريال</p>
                        </div>


                        <div className="d-flex justify-content-between">
                          <p className="mb-2">{myState.copont_price_title}</p>
                          <p className="mb-2">${myState.jam_takhfif.toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
                        </div>



                        <button type="button" onClick={onRialGatewayClicked} className="btn btn-info btn-block btn-lg">
                          <div className="d-flex justify-content-between">
                            <span>${myState.jam_pardakht_dollar.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
                            <span>{myState.btn_dollar_pardakht_title} <FontAwesomeIcon icon={faLongArrowAltLeft} /></span>
                          </div>
                        </button>
                        <button type="button" onClick={onRialGatewayClicked} className="btn btn-info btn-block btn-lg">
                          <div className="d-flex justify-content-between">
                            <span>ريال {myState.jam_pardakht_rial.toLocaleString('en-US', { minimumFractionDigits: 0 })}</span>
                            <span>{myState.btn_rial_pardakht_title} <FontAwesomeIcon icon={faLongArrowAltLeft} /> </span>
                          </div>
                        </button>


                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

   {modalState &&  <AcceptModal changed={onRialGatewayAcceptClicked}/>}
  
  </>
  )
}

export default ShoppingCard