import React,{useEffect,useState} from "react";
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import HomeUniqFeatures from "../../../components/site/Home/HomeUniqFeatures/HomeUniqFeatures";
import HomeAnalyze from "../../../components/site/Home/HomeAnalyze/HomeAnalyze";
import HomeCollaborate from "../../../components/site/Home/HomeCollaborate/HomeCollaborate";
import HomeProducts from "../../../components/site/Home/HomeProducts/HomeProducts";
import HomeFAQ from "../../../components/site/Home/HomeFAQ/HomeFAQ";
import HomeLastCourse from "../../../components/site/Home/HomeLastCourse/HomeLastCourse";
import {  useDispatch, useSelector } from 'react-redux'
import { addChangePageTitle } from '../../../store/pathActions'
import { Helmet } from 'react-helmet';
import Utils from "../../../modules/utils";

const SiteHome = (props) => {

    const [myTitleState,setMyTitleState]=useState("Tradinglocally")
    const tranlates = useSelector((state) => state.tranlates)
     const dispatch = useDispatch();
        useEffect(() => {
            dispatch(addChangePageTitle("Tradinglocally"));
            loadTitle();
           
        })

        const loadTitle=async()=>{
            const result2 = await tranlates;
            setMyTitleState((new Utils).translateWord(result2,"Tradinglocally"))
        }
    return (


        <Wrapper>
          <Helmet>
          <title>{ myTitleState }</title>
        </Helmet>
            <HomeUniqFeatures />

            <HomeAnalyze />

            <HomeCollaborate />







            <HomeProducts />


            {/* <section className="need_section aos-init" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                <div className="container">
                    <div className="need_block">
                        <div className="need_text">
                            <div className="section_title">
                                <h2>Need support ? contact our team</h2>
                                <p><i className="icofont-clock-time"></i> Mon - Fri: 9 am to 5 am</p>
                            </div>
                        </div>
                        <div className="need_action">
                            <a href="tel:1234567899" className="btn"><i className="icofont-phone-circle"></i> +1 123 456 7890</a>
                            <a href="#faqBlock" className="faq_btn">Read The FAQ </a>
                        </div>
                    </div>
                </div>
            </section> */}






            <HomeFAQ />


            <HomeLastCourse />

        </Wrapper>
    )
}

export default SiteHome